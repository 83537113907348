import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: any;
  @Input() backroute: any;

  constructor() { }

  ngOnInit() {
    console.log("Foi Chamado",this.title);
    console.log("Foi Chamado",this.backroute);

  }

}
