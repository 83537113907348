import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';


@Component({
  selector: 'app-infowelcome',
  templateUrl: './infowelcome.page.html',
  styleUrls: ['./infowelcome.page.scss'],
})
export class InfowelcomePage{

  constructor(
    private androidPermissions: AndroidPermissions,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
      result => console.log('Has permission?',result.hasPermission),
      err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
    );

  }
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
