import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';
import { ApiServiceService } from 'src/app/api/api-service.service';
import { IonLoaderService } from 'src/app/ion-loader.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { CalendarioPage } from 'src/app/pages/calendario/calendario.page';
import { Router } from '@angular/router';
import { Input } from '@angular/core';

@Component({
  selector: 'app-confirma-escala',
  templateUrl: './confirma-escala.page.html',
  styleUrls: ['./confirma-escala.page.scss'],
})
export class ConfirmaEscalaPage implements OnInit {
  @Input() isPage: Boolean = true;
  item;
  urlmaps;
  data;
  abc  = true;
  constructor(public apiservice: ApiServiceService, public modalController: ModalController,public sanitizer:DomSanitizer,
    private loadingController: IonLoaderService,
    private alertController: AlertController,
    private router: Router,) { }


  async ionViewWillEnter(){
    console.log(this.item.escala_status);
    this.urlmaps = this.sanitizer.bypassSecurityTrustResourceUrl("https://maps.google.com/maps?q="+this.item.cep+"&iwloc=&output=embed");
  }

  ngOnInit() {


  }

  reloadCurrentRoute(gotorote) {
    const currentUrl = gotorote;
    this.router.navigateByUrl('/welcome', {skipLocationChange: true}).then(() => {
      console.log("vai para a rota: ",currentUrl);
        this.router.navigate([currentUrl]);
    });
}

  async actionEscala(action,info_escala){

    this.loadingController.simpleLoader("Validando informações");
    await this.apiservice.cadastra_escala(info_escala,action)
    .subscribe(
      result => {
        this.data = result;
      },
      async error => {
        this.loadingController.errorAlert();
        this.loadingController.dismissLoader();
      },
      () => {

        this.loadingController.dismissLoader();
        if(this.data.status == false){
          this.loadingController.errorAlert(this.data.msg);
        } else {
          if(action == "aceita"){
            this.item.escala_status = "confirmado";
          } else {
            this.item.escala_status = "recusa";
          }
          
          this.reloadCurrentRoute("calendario");
          // this.router.navigate(["/welcome/"]);
          this.dismiss();
          // CalendarioPage.exp_getinfo();
          this.loadingController.simpleLoader(this.data.msg);
          // this.reloadCurrentRoute();
          // this.router.navigateByUrl('/welcome', { replaceUrl: true });
          // this.router.navigateByUrl('/calendario', { replaceUrl: true });
        }
        // 'onCompleted' callback.
        // No errors, route to new page here
      }
    );
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
