import { Injectable } from '@angular/core';
import { LoadingController,AlertController } from '@ionic/angular';
// import { AlertController, LoadingController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})

export class IonLoaderService {

  constructor(
    public loadingController: LoadingController,
    private alertController: AlertController,
  ) {}

  simpleLoader(text ? : string | "Aguarde,Carregando...", time ? : number | 2500) {
    if (!text) {
      text = "Aguarde, carregando...";
    } else {
      text = text;
    }

    this.loadingController.create({
      message: text,
      spinner: 'crescent',
      duration: time,
      translucent: true,
      // cssClass: 'custom-class custom-loading',
      backdropDismiss: true
    }).then((response) => {
      response.present();
    });
  }
  errorAlert(text ? : string | "Essa requisição levou mais tempo do que o esperado, tente novamente em instantes.") {
    if (!text) {
      text = "Essa requisição levou mais tempo do que o esperado, tente novamente em instantes.";
    } else {
      text = text;
    }

    const alert = this.alertController.create({
      header: 'Erro!',
      message: text,
      cssClass: 'danger',
      buttons: ['OK']
    }).then((response) => {
      response.present();
    });


  }
  simpleAlert(text ? : string | "...") {
    if (!text) {
      text = "..";
    } else {
      text = text;
    }

    const alert = this.alertController.create({
      header: '',
      message: text,
      buttons: ['OK']
    }).then((response) => {
      response.present();
    });


  }

  dismissLoader() {
    this.loadingController.dismiss();
  }


}
