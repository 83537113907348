import { Injectable } from '@angular/core';
import { LocalNotifications } from '@capacitor/local-notifications';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {

  constructor(){}

  async showLocalNotification( title : string, text : string, addSeconds: number = 1){
    const randomId = Math.floor(Math.random() * 10000) + 1;

    console.log("Agora:"+new Date(Date.now() )+" Disparara em"+new Date(Date.now() + (1000* addSeconds)))
    if(addSeconds == 1){
      LocalNotifications.schedule({
        notifications:[
        {

            title : title,
            body : text,
            id : randomId
        }
        ]

        });
    } else {
      LocalNotifications.schedule({
        notifications: [
          {
            title: title,
            body: text,
            id: randomId,
            schedule: {
              at: new Date(Date.now() + (1000* addSeconds)), // in a minute

              // every: "minute"

            }

          }
        ]
      });
    }



  }
}

